<template>
    <div class="main ">
        <el-card class="card_box">
            <div slot="header" class="card_title" @click="$router.push({path:'/information/reportinfo'})">
                <i class="el-icon-arrow-left
                "></i>
                <span>详情</span>
            </div>
            <div class="add_main">
                <span class="font_weight_bold">举报人信息</span>
                <div class="margin_top_20">
                    <el-form label-position="right" label-width="120px" :model="formLabelAlign">

                        <el-form-item label="账号名称">
                            <el-input v-model="formData.users.nickname"></el-input>
                        </el-form-item>
                        <el-form-item label="账号ID">
                            <el-input v-model="formData.users.id"></el-input>
                        </el-form-item>
                        <el-form-item label="头像">
                            <el-avatar shape="square" :size="size" :src="formData.users.avatar_img"></el-avatar>
                        </el-form-item>
                        <div class="margin_top_40 font_weight_bold">作者信息</div>
                        <el-form-item label="账号名称" class="margin_top_20">
                            <el-input v-model="formData.information.author.nickname"></el-input>
                        </el-form-item>
                        <el-form-item label="账号ID">
                            <el-input v-model="formData.information.author.id"></el-input>
                        </el-form-item>
                        <el-form-item label="账号类别">
                            <el-select v-model="formData.information.author.type" placeholder="">
                                <el-option label="普通用户" value="1">
                                </el-option>
                                <el-option label="个人用户" value="2">
                                </el-option>
                                <el-option label="企业用户" value="3">
                                </el-option>
                                <el-option label="项目用户" value="4">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <div class="margin_top_40 font_weight_bold">文章信息</div>
                        <el-form-item label="标题">
                            <el-input v-model="formData.information.title"></el-input>
                        </el-form-item>
                        <el-form-item label="简介">
                            <el-input v-model="formData.information.illustrate"></el-input>
                        </el-form-item>
                            <el-form-item label="语言">
                                <el-select v-model="formData.information.language" placeholder="">
                                    <el-option label="简体中文" value="1">
                                    </el-option>
                                    <el-option label="英语" value="2">
                                    </el-option>
                                    <el-option label="日语" value="3">
                                    </el-option>
                                    <el-option label="繁体中文" value="4">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        <el-form-item label="摘要">
                            <el-input v-model="formData.information.content"></el-input>
                        </el-form-item>


                        <el-form-item>
                            <div class="flex justify_content_c align_items_c">

                                <el-button class="custom_btn_width_100 " @click="$router.push({path:'/information/reportinfo'})">返回</el-button>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
            </div>

        </el-card>
    </div>
</template>

<script>
    import { getReportDetails } from "@/api/information";
    export default {

        name: '',
        data() {
            return {
                value: "",
                formData: {

                 
                }
            }
        },
        created() {
            this.getUsersdetails()
        },
        methods: {
            // 获取用户详情
            getUsersdetails() {
                const that = this;
                getReportDetails({ id: that.$route.query.id, report_type: 1 }).then((res) => {
                    if (res.code == 200) {
                        that.formData = res.data
                        // that.formData.information.author.type = that.formData.information.author.type.toString()
                        that.formData.information.author.type=that.formData.information.author.type.toString()
                        that.formData.information.language = that.formData.information.language.toString()

                    } else {
                        that.$message.error(res.message);
                    }
                }).catch((error) => {
                    that.$message.error(error.message);
                });
            },



        }

    }
</script>

<style scoped>
    .main {
        width: calc(100% - 40px);
    }

    .add_main {
        width: 70%;
        margin: 0 auto;
    }
</style>